import _Vue from 'vue';
import {getRequest} from '@pazion/pazion-core/src/services/auth-service';
import {stringifyUrl} from 'query-string';



export async function getCountries(filter?: any, limit?: number) {
    filter = filter || {};
    if (filter) {
        const url = stringifyUrl({
            url: 'base/country',
            query: {
                filter: JSON.stringify(filter),
                limit: limit
            }
        });
        return (await getRequest(url)).data;

    }
    return (await getRequest('catalog/country')).data;
}


export const checkCardConditionalsRedirect = (instance: typeof _Vue) => {
    // Current host
    const checkedDomain = window.location.hostname;
    // Has domain === env variable

    //@ts-ignore
    const parsedCardSubdomainCheck = checkedDomain.includes(process.env.VUE_APP_BACKEND_BASE_URL
    .replace(/\/$/, "")
    .replace(/https?:\/\/\S+/g, ""));

    //has admin or login and not a localHost
    //@ts-ignore
    const notServicePage = !checkedDomain.includes('admin') && instance.$route.name !== "membership pass";
    console.log('admin check', !checkedDomain.includes('admin'), instance.$route.name !== "membership pass");
    
    //@ts-ignore
    return instance.$membersCardManagement && notServicePage;
}


export const createDownloadPwaLink = () => {
    let deferredPrompt;
    window.addEventListener("beforeinstallprompt", (e) => {
        // Prevent the mini-infobar from appearing on mobile
        e.preventDefault();
        // Stash the event so it can be triggered later.
        // Update UI notify the user they can install the PWA
        deferredPrompt = {
            promptEvent: e,
            status: true 
        }
        console.log('set listener', deferredPrompt);
        return deferredPrompt;
    });

      // window.addEventListener('appinstalled', () => {
      //   // Log install to analytics
      //   console.log('INSTALL: Success');
      // });
}